import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <div>
        <h1>Contact Info</h1>
        <p>
          My Linkdin{" "}
          <a
            href="https://www.linkedin.com/in/david-townsend-0999242/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Profile
          </a>
        </p>
      </div>
    </Layout>
  )
}

export default ContactPage
